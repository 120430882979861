* {
  box-sizing: border-box;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  padding: 0;
  margin: 0;
}

html {
  font-size: 1.1em;
}

body {
  --system-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --system-mono: Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace;

  --color-focus: hsl(44, 100%, 60%);

  --color-bg: #fff;
  --color-text: #444;
  --color-text-strong: #000;
  --color-text-secondary: #a0a0a0;
  --color-link: #dcdcdc;
  --color-light: #f0f0f0;

  --color-header-underline-link: #555;
  --color-header-underline-focus: hsl(44, 100%, 30%);

  --color-nav-bg: #282828;
  --color-nav-link: #dcdcdc;
  --color-nav-link-secondary: #999;

  --color-overlap-bg: #dcdcdc;
  --color-overlap-link: #444;
  --color-overlap-link-strong: #000;

  --color-search-bg: #3a3a3a;
  --color-search-border: #888;

  --space-v-s: .2rem;
  --space-v-m: .4rem;
  --space-v-l: .8rem;
  --space-v-xl: 1.6rem;

  --space-h-xs: .3rem;
  --space-h-s: .6rem;
  --space-h-m: 1.2rem;
  --space-h-l: 2.4rem;

  --margin-h: calc(var(--space-h-xs) + var(--space-h-s) + var(--space-h-m));

  --font-m: 1rem;
  --font-l: 1.8rem;
  --font-xl: 3.2rem;

  --line-xs: 1.1;
  --line-s: 1.4;
  --line-m: 1.6;

  --border-radius: .2em;
  --border-thickness: 1pt;

  --time-fade: .3s;
  --time-move: .15s;
  --time-target-focus-fade: 4s;

  color: var(--color-text);
  font-family: var(--system-sans-serif);
  line-height: var(--line-m);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: none;
}

ul li {
  width: 100%;
}

/* Space */

.tiles:first-child::before {
  content: "";
  flex: 1 0 1%;
  height: 100%;
}

.gutter-separator {
  flex: 1 0 1%;
  margin: var(--space-v-xl) 0;
  border-right: 1px solid var(--color-link);
}

.space-gutter {
  flex: 0 0 auto;
  margin: var(--space-v-xl) 0;
  width: var(--margin-h);
  display: flex;
  transition: background-color var(--time-fade);
}

.space-gutter > a {
  flex: 1 1 auto;
  background-color: #00000000;
  display: flex;
  flex-direction: column;
}

.space-gutter > a > span {
  font-size: var(--font-l);
  font-weight: 200;
  color: var(--color-text);
  transition: color var(--time-fade);
  text-align: center;
  width: var(--margin-h);
}

.space-gutter > a::after {
  content: "";
  margin-left: calc((var(--margin-h) - var(--space-h-s)) / 2);
  background-color: #00000000;
  border-radius: var(--border-radius);
  transition: background-color var(--time-fade);
  width: var(--space-h-s);
  height: 100%;
}

.space-gutter.inactive > a {
  display: none;
}

.space-gutter:hover > a > span, .space-gutter.dragged-over-gutter > a > span {
  color: var(--color-focus);
}

.space-gutter:hover > a::after, .space-gutter.dragged-over-gutter > a::after {
  background-color: var(--color-focus);
}

/* Tiles */

.tiles {
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
}

.tiles > * {
  max-width: 46em;
}

.tile {
  flex: 100 1 0;
  overflow: auto;
}

.tile-separator {
  flex: 0 0 auto;
  background-color: var(--color-link);
  margin: var(--space-v-xl) 0;
  width: 1px;
}

/* Assemblage Logo */

.assemblage-logo {
  line-height: 0;
}

a.assemblage-logo, a:hover.assemblage-logo, a:focus.assemblage-logo {
  box-shadow: none;
}

.assemblage-logo img {
  height: 0.9em;
  padding-top: 0.1em;
  filter: brightness(0);
  transition: filter var(--time-fade);
}

.assemblage-logo:hover img {
  filter: brightness(100%);
}

/* Header */

header,
.main {
  padding: var(--space-v-l) 0;
}

header {
  margin-top: var(--space-v-xl);
  padding: 0 var(--margin-h);
  line-height: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header a {
  font-size: var(--font-xl);
}

header a .link-prefix {
  display: none;
}

header a .link-name {
  display: none;
}

header .tile-close {
  flex: 0 0 auto;
  line-height: 0;
  margin-bottom: 0.2em;
  font-size: var(--font-l);
  color: var(--color-text);
  transition: color var(--time-fade);
  cursor: pointer;
  font-weight: 200;
}

header .tile-close:focus,
header .tile-close:hover {
  color: var(--color-focus);
}

header .actions {
  display: flex;
  align-items: center;
}

header .actions > * {
  margin-right: var(--space-h-s);
}

/* Broadcasts */

button.start-broadcast {
  font-size: var(--font-xl);
  cursor: pointer;
  background: none!important;
  border: none!important;
  padding: 0!important;
  margin: 0!important;
  transition: opacity var(--time-fade);
}

button.start-broadcast.busy {
  opacity: 0.5;
  cursor: default;
}

@keyframes pulsateBrightness {
  0% {
    filter: brightness(0);
  }
  50% {
    filter: brightness(100%);
  }
  100% {
    filter: brightness(0);
  }
}

button.start-broadcast.active img {
  animation: pulsateBrightness 1s infinite;
  cursor: default;
}

.broadcasts {
  margin-top: var(--space-v-m);
  margin-left: var(--margin-h);
}

@keyframes pulsateColor {
  0% {
    color: inherit;
  }
  50% {
    color: var(--color-focus);
  }
  100% {
    color: inherit;
  }
}

.broadcasts > *::before,
header .external-broadcast::before {
  content: '⦿';
  font-weight: bold;
  padding-right: var(--space-h-xs);
  animation: pulsateColor 1s infinite;
}

.broadcasts input[type=text] {
  font-size: var(--font-m);
  padding: 0 var(--space-h-xs);
  width: calc(min(70%, 32em));
}

header .external-broadcast {
  display: inline-block;
  font-size: var(--font-l);
}

/* .main */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.main {
  animation: fadeIn var(--time-fade);
}

/* Article Links */

.main > article {
  position: relative;
  display: flex;
  align-items: stretch;
  flex-direction: row-reverse;
}

.main > article > a,
.main > article > .section-without-id {
  flex: 0 0 var(--margin-h);
  padding-top: var(--space-v-s);
  padding-bottom: var(--space-v-s);
  padding-left: var(--space-h-m);
  padding-right: var(--space-h-xs);
  box-shadow: none;
}

.main > .tile-branches {
  margin: 0 var(--margin-h);
}

.main > article > a:hover,
.main > article > a:focus {
  box-shadow: none;
}

.main > article > a::after,
.main > article > .section-without-id::after {
  content: '';
  display: block;
  height: 100%;
  border-radius: var(--border-radius);
  transition: background-color var(--time-fade) ease;
  background-color: var(--color-light);
  margin: 0 calc(var(--space-h-xs) / 2);
}

.main > article > a:hover::after,
.main > article > a.highlighted:hover::after,
.main > article > a.empty:hover::after,
.main > article.selected > a::after,
.main > article.selected > a.highlighted::after,
.main > article.selected > a.empty::after,
.main > article > .section-without-id:hover::after,
.main > article > .section-without-id:focus::after,
.main > article.selected > .section-without-id::after {
  background-color: var(--color-focus);
}

.main > article > a.highlighted::after {
  background-color: var(--color-link);
  margin: 0;
}

.main > article > a > * {
  display: none;
}

.main > article > section {
  min-width: 0;
  flex: 1 1 auto;
  margin-right: var(--margin-h);
}

article.placeholder > span::after {
  color: var(--color-link);
}

article.placeholder:hover > .section-without-id::after,
article.placeholder:focus > .section-without-id::after {
  background-color: var(--color-link);
}

.markup-examples,
.markup-examples h2,
.markup-examples p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.markup-examples > * {
  vertical-align: bottom;
}

.markup-examples .markup-visible {
  display: inline-block;
  width: 6em;
}

.markup-examples .markup-example-marker {
  display: inline-block;
  font-weight: bold;
  font-family: var(--system-mono);
  color: var(--color-text);
}

.markup-examples .markup-example h2 {
  padding: 0;
}

.markup-examples .markup-example aside {
  color: var(--color-link);
}

.markup-examples:hover .markup-example aside,
.markup-examples:focus .markup-example aside {
  color: var(--color-text-secondary);
}

.markup-example {
  display: inline-block;
}

article.placeholder div * {
  color: var(--color-text-secondary);
  cursor: text;
  transition: color var(--time-fade);
}

article.placeholder:hover div *,
article.placeholder:focus div * {
  color: var(--color-text);
}

article.placeholder:hover div .markup-example-marker,
article.placeholder:focus div .markup-example-marker {
  color: var(--color-text-strong);
}

.placeholder .markup-examples .markup-example-separator {
  display: inline-block;
  color: var(--color-link);
  width: 2em;
  text-align: center;
}

/* Siblings */

.rotated-arrow {
  display: inline-block;
  transform: rotate(90deg);
}

/* Siblings & Overlaps */

aside nav ul {
  flex: 1 1 auto;
  padding: var(--space-v-s) var(--space-h-xs);
  border-radius: var(--border-radius);
  overflow: hidden;
  white-space: nowrap;
}

aside nav {
  display: flex;
  align-items: center;
  padding-top: var(--space-v-s);
  padding-bottom: var(--space-v-s);
}

aside hr {
  margin: var(--space-v-s) 0;
  border: none;
  height: 0;
}

.overlap:before, .sibling.before::after, .sibling.after:before {
  content: '';
  display: block;
  border-style: solid;
  height: 0;
  width: 0;
  margin-left: var(--space-h-s);
}

.overlap:before {
  border-color: transparent var(--color-overlap-bg);
}

.sibling.after:before {
  border-color: transparent var(--color-nav-bg);
}

.sibling.after:before, .overlap:before {
  border-width: var(--space-v-l) 0 0 calc(1.4 * var(--space-v-l));
  margin-bottom: calc(-2 * var(--space-v-s));
  margin-top: calc(-1 * var(--space-v-s));
}

.sibling.before::after {
  border-color: transparent var(--color-nav-bg);
  border-width: 0 0 var(--space-v-l) calc(1.4 * var(--space-v-l));
  margin-top: calc(-2 * var(--space-v-s));
  margin-bottom: calc(-1 * var(--space-v-s));
}

/* Header & Siblings */

.sibling a,
.sibling a:hover,
.sibling a:focus,
.sibling a:active,
.sibling .ancestor-title,
.sibling .ancestor-separator {
  box-shadow: none;
  transition: color var(--time-fade) ease;
}

.sibling nav ul,
.header {
  background-color: var(--color-nav-bg);
}

.sibling nav a,
.header a {
  color: var(--color-nav-link);
}

.sibling nav .ancestor-title,
.sibling nav .ancestor-separator {
  color: var(--color-nav-link-secondary);
}

.sibling nav a:hover,
.sibling nav a:focus,
.sibling nav a:active,
.sibling nav a:hover .ancestor-title,
.sibling nav a:focus .ancestor-title,
.sibling nav a:active .ancestor-title,
.sibling nav a:hover .ancestor-separator,
.sibling nav a:focus .ancestor-separator,
.sibling nav a:active .ancestor-separator {
  color: var(--color-focus);
}

/* Overlaps */

.overlap nav ul {
  background-color: var(--color-overlap-bg);
}

.overlap nav ul a {
  color: var(--color-overlap-link);
  box-shadow: inset 0pt -0.55em 0pt -0em var(--color-overlap-bg);
  transition: box-shadow var(--time-fade) ease;
}

.overlap nav ul a:hover,
.overlap nav ul a:focus {
  color: var(--color-overlap-link-strong);
  box-shadow: inset 0pt -0.55em 0pt -0em var(--color-focus);
}

.overlap nav svg {
  vertical-align: text-bottom;
  height: 1.1rem;
}

.overlap nav ul svg {
  fill: var(--color-text);
  transition: fill var(--time-fade) ease;
}

.overlap nav ul a:hover svg,
.overlap nav ul a:focus svg {
  fill: var(--color-focus);
}

/* General styles */

mark {
  padding: 0 var(--space-h-xs);
  background-color: var(--color-link);
}

s {
  text-decoration: none;
  background-image: linear-gradient(
    transparent .6em,
    var(--color-text-secondary) .6em,
    var(--color-text-secondary) .75em,
    transparent .75em
  );
}

code {
  font-family: var(--system-mono);
}

aside {
  margin-left: var(--space-h-m);
}

aside, aside h1, aside h2 {
  color: var(--color-text-secondary);
}

.main h1, ul.bullet.title::before {
  padding-top: var(--space-v-xl);
  line-height: var(--line-xs);
  padding-bottom: var(--space-v-l);
}

.main h2, ul.bullet.heading::before {
  padding-top: var(--space-v-l);
  line-height: var(--line-xs);
  padding-bottom: var(--space-v-l);
}

nav, .header {
  line-height: var(--line-s);
}

h1, strong {
  color: var(--color-text-strong);
}

h1, ul.bullet.title::before {
  font-size: var(--font-xl);
}

h2, ul.bullet.heading::before {
  font-size: var(--font-l);
}

h1 strong {
  font-weight: 900;
}

p {
  min-height: calc(var(--font-m) * var(--line-m));
}

h1, h2, p {
  white-space: pre-wrap;
}

a {
  text-decoration: none;
  color: var(--color-text);
  box-shadow: inset 0pt -0.55em 0pt -0em var(--color-link);
  transition: box-shadow var(--time-fade) ease;
}

a:hover, a:focus {
  box-shadow: inset 0pt -0.55em 0pt -0em var(--color-focus);
}

small {
  font-size: inherit;
  color: var(--color-text-secondary);
}

ul.bullet {
  display: flex;
}

ul.bullet::before {
  content: '•';
  flex: 0 0 auto;
  width: var(--space-h-m);
  font-weight: bold;
}

ul.bullet.title::before {
  width: var(--space-h-l);
}

blockquote {
  border-left: var(--space-h-xs) solid var(--color-link);
  margin-left: var(--space-h-m);
  padding-left: var(--space-h-xs);
  margin-top: var(--space-v-l);
  margin-bottom: var(--space-v-l);
}

/* Edits */

@keyframes sectionCaretBlinking {
  0% {
    border-right: 1px solid var(--color-text-strong);
  }
  50% {
    border-right: 1px solid var(--color-text-strong);
  }
  51% {
    border-right: 1px solid rgba(0, 0, 0, 0);
  }
  100% {
    border-right: 1px solid rgba(0, 0, 0, 0);
  }
}

section:focus {
  animation: sectionCaretBlinking 1s infinite;
  border-right: 1px solid rgba(0, 0, 0, 0);
  padding-right: 1px;
}

.main section, .main section * {
  outline: none;
}

.drop-zone-container {
  position: relative;
}

.drop-zone {
  display: flex;
  align-items: center;
  z-index: 2;
  position: absolute;
  width: calc(100% - (2 * var(--margin-h)));
  margin: 0 var(--margin-h);
  height: calc(2 * var(--space-v-xl));
  bottom: calc(-1 * var(--space-v-xl));
}

.drop-zone.before-dragged {
  height: calc(var(--space-v-xl));
  align-items: flex-end;
  bottom: 0;
}

.drop-zone.after-dragged {
  height: calc(var(--space-v-xl));
  align-items: flex-start;
  top: 0;
}

.drop-zone > * {
  flex: 1 1 auto;
  height: var(--space-v-m);
  border-radius: var(--border-radius);
  transition: background-color var(--time-fade);
}

.drop-zone.before-dragged > * {
  height: var(--space-v-s);
}

.drop-zone.after-dragged > * {
  height: var(--space-v-s);
}

.drop-zone.drag-active > * {
  background-color: var(--color-focus);
}

.close-tile-btn {
  cursor: pointer;
}

a .link-prefix {
  display: none;
}

.cursor-text {
  cursor: text;
}

.block span.text-markup {
  font-size: 0;
  transition: font-size var(--time-move);
}

.block:hover span.text-markup,
.block.active span.text-markup {
  font-size: inherit;
}

.block span.text-markup.visible {
  font-size: inherit;
  transition: none;
}

span.text-markup {
  color: var(--color-text-secondary);
}

.text-drop-zone {
  padding: 0 var(--space-h-xs);
  width: var(--space-h-s);
  height: 1em;
  transition: background-color var(--time-fade);
  border-radius: var(--border-radius);
}

.text-drop-zone.active {
  background-color: var(--color-focus);
}
